.textarea {
  width: 340px;
}

@media (max-width: 340px) {
  .textarea {
    width: 120px;
  }
}

@media (max-width: 390px) {
  .rate-text {
    display: none;
  }
  .textarea {
    width: 180px;
  }
}

@media (max-width: 430px) {
  .textarea {
    width: 260px;
  }
}

@media (max-width: 670px) and (orientation: landscape) {
  .rate-text {
    display: none;
  }
}

@media (max-width: 660px) {
  .textarea {
    width: 300px;
  }
  .rate-text {
    display: none;
  }
}

@media (max-width: 800px) {
  .textarea {
    width: 300px;
  }
}
